<template>
  <!-- Sidebar Part -->
  <nav class="sidebar">
    <div class="sidebar-header">
      <router-link :to="{ name: 'Login' }" class="sidebar-brand">
        <img src="/assets/images/logo.png" />
      </router-link>
      <div
        :class="[
          'sidebar-toggler mobile_sidebar_toggler',
          isSidebarOpen ? 'active' : 'not-active',
        ]"
        @click="$emit('toggleSidebar')"
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="sidebar-body faq_navbar">
      <div class="list-group" id="list-tab" role="tablist">
        <h4 class="faq_tabs_heading">Accounts</h4>
        <a
          class="list-group-item list-group-item-action disabled"
          id="CreateAdditionalAccount-list"
          data-bs-toggle="list"
          href="#CreateAdditionalAccount"
          role="tab"
          aria-controls="CreateAdditionalAccount"
        >
          Create additional account
        </a>
        <a
          class="list-group-item list-group-item-action disabled"
          id="Multi-accountPlans-list"
          data-bs-toggle="list"
          href="#Multi-accountPlans"
          role="tab"
          aria-controls="Multi-accountPlans"
        >
          Multi - account plans
        </a>
        <a
          class="list-group-item list-group-item-action disabled"
          id="JoinAnotherAccount-list"
          data-bs-toggle="list"
          href="#JoinAnotherAccount"
          role="tab"
          aria-controls="JoinAnotherAccount"
        >
          Join Another Account
        </a>
        <a
          class="list-group-item list-group-item-action disabled"
          id="SwitchBetweenAccounts-list"
          data-bs-toggle="list"
          href="#SwitchBetweenAccounts"
          role="tab"
          aria-controls="SwitchBetweenAccounts"
        >
          Switch between accounts
        </a>
        <a
          class="list-group-item list-group-item-action disabled"
          id="FrozenAccounts-list"
          data-bs-toggle="list"
          href="#FrozenAccounts"
          role="tab"
          aria-controls="FrozenAccounts"
        >
          Frozen accounts
        </a>
        <a
          class="list-group-item list-group-item-action disabled"
          id="FullListOfLimits-list"
          data-bs-toggle="list"
          href="#FullListOfLimits"
          role="tab"
          aria-controls="FullListOfLimits"
        >
          Full list of limits </a
        ><br />

        <h4 class="faq_tabs_heading">Management</h4>
        <a
          class="list-group-item list-group-item-action disabled"
          id="HowToAddOrRemocePartners-list"
          data-bs-toggle="list"
          href="#HowToAddOrRemocePartners"
          role="tab"
          aria-controls="HowToAddOrRemocePartners"
        >
          How to add or remoce Partners
        </a>
        <a
          class="list-group-item list-group-item-action disabled"
          id="HowToAddOrRemoceTeam-list"
          data-bs-toggle="list"
          href="#HowToAddOrRemoceTeam"
          role="tab"
          aria-controls="HowToAddOrRemoceTeam"
        >
          How to add or remoce Team
        </a>
        <a
          class="list-group-item list-group-item-action active"
          id="HowToAddOrRemoceUser-list"
          data-bs-toggle="list"
          href="#HowToAddOrRemoceUser"
          role="tab"
          aria-controls="HowToAddOrRemoceUser"
        >
          How to add or remoce user
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    isSidebarOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hideSidebar() {
      this.$emit("hideSidebar");
    },
  },
};
</script>
<style></style>
