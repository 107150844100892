<template>
  <div :class="['main-wrapper', isSidebarOpen ? 'sidebar-open' : '']">
    <Sidebar
      @toggle-sidebar="isSidebarOpen = !isSidebarOpen"
      :is-sidebar-open="isSidebarOpen"
      @hide-sidebar="isSidebarOpen = false"
    />
    <div class="page-wrapper">
      <Header @toggle-sidebar="isSidebarOpen = !isSidebarOpen" />
      <router-view />
    </div>
  </div>
</template>
<script>
import Sidebar from "./Sidebar.vue";
import Header from "./Header.vue";

export default {
  components: {
    Sidebar,
    Header,
  },
  data() {
    return {
      isSidebarOpen: false,
    };
  },
};
</script>
<style lang="css">
/* @import url("/dashboard-assets/css/style.min.css"); */
</style>
